
import { Options, Vue } from 'vue-class-component';
import MapQuiz from '@/components/MapQuiz.vue'; // @ is an alias to /src
import LocationRuby from '@/components/LocationRuby.vue'; // @ is an alias to /src
import info_all from '@/info/jp_shikuchoson.json';

@Options({
  components: {
    MapQuiz,
    LocationRuby
  },
  props:["prefCode"]
})
export default class JpShikuchoson extends Vue {
  answer:any = {};
  prefCode!:any;
  geojsonPath!:string;
  overwrapText = "";

  info:any;

  created(){
    //this.prefCode = this.$route.params.code;
    this.geojsonPath = `/geo/jp_shikuchoson_${this.prefCode}`
    const info = info_all.items.filter((item:any)=>item.id.indexOf(this.prefCode)==0);
    const groups = Array.from(new Set(info.map(item=>item.group)));
    this.info = {
      id:"jp_shichoson_"+this.prefCode,
      items:info,
      groups:groups,
    }
  }

  updateAnswer(newAnswer:any){
    this.answer = newAnswer;
    this.overwrapText = newAnswer.name;
  }
}
